import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { kebabCase } from "lodash";
import { v4 } from "uuid";
class CategoryListTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    let categories = [];
    let categoryCounter = [];

    posts.forEach(({ node: post }) => {
      if (post.frontmatter.category) {
        if (!categoryCounter[post.frontmatter.category]) {
          categoryCounter[post.frontmatter.category] = 1;

          categories.push({
            name: post.frontmatter.category,
            slug: "/category/" + kebabCase(post.frontmatter.category),
          });
        }
      }
    });

    if (categories && categories.length) {
      return (
        <div className="content">
          <ul>
            {categories &&
              categories.map((category) => {
                return (
                  <li key={v4()}>
                    <Link
                      className="title has-text-primary is-size-4"
                      to={category.slug}
                    >
                      {category.name}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

CategoryList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function CategoryList() {
  return (
    <StaticQuery
      query={graphql`
        query CategoryListQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___title] }
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "blog-post" } } }
            }
          ) {
            edges {
              node {
                frontmatter {
                  category
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <CategoryListTemplate data={data} count={count} />
      )}
    />
  );
}
